<template scoped>
  <Panel-CRUD
    ref="crud"
    :guid="guid"
    :endpoint="endpoint"
    :toPage="toPage"
    :resourceServer="resourceServer"
    :resourceId="resourceId"
    title="User"
    :create="create"
    :resourceServerApi="false"
    apiPath="Backend/User"
  >
    <template>
      <el-table-column prop="name" label="Name"> </el-table-column>
      <el-table-column prop="identity" label="Identity">
        <template slot-scope="scope">
          <div v-for="(item, index) in scope.row.identity" :key="index">
            <el-tag>{{ item.provider }}</el-tag>
            <el-tag type="info">{{ item.identity }}</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="enable" label="Live Mode">
        <template slot-scope="scope">
          <el-switch
            @change="$refs.crud.setEnable(scope.row)"
            v-model="scope.row.enable"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="action" label="Action">
        <template slot-scope="scope">
          <el-row>
            <el-col :span="24">
              <el-tooltip
                class="item"
                effect="dark"
                content="Edit"
                placement="right"
              >
                <el-button
                  @click="edit(scope.row)"
                  icon="el-icon-edit"
                  circle
                ></el-button>
              </el-tooltip>
            </el-col>
            <el-col :span="24" :style="{ marginTop: '10px' }">
              <el-tooltip
                class="item"
                effect="dark"
                content="Role"
                placement="right"
              >
                <el-button
                  @click="role(scope.row)"
                  icon="el-icon-guide"
                  circle
                ></el-button>
              </el-tooltip>
            </el-col>
            <el-col :span="24" :style="{ marginTop: '10px' }">
              <el-tooltip
                class="item"
                effect="dark"
                content="Copy Id"
                placement="right"
              >
                <el-button
                  @click="$refs.crud.copyId(scope.row)"
                  icon="el-icon-document-copy"
                  circle
                ></el-button>
              </el-tooltip>
            </el-col>
            <el-col :span="24" :style="{ marginTop: '10px' }">
              <el-tooltip
                class="item"
                effect="dark"
                content="Delete"
                placement="right"
              >
                <el-button
                  type="danger"
                  @click="$refs.crud.remove(scope.row)"
                  icon="el-icon-delete"
                  circle
                ></el-button>
              </el-tooltip>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
    </template>
  </Panel-CRUD>
</template>
<script>
export default {
  props: {
    guid: String,
    endpoint: String,
    toPage: Function,
    resourceServer: String,
    resourceId: String,
  },
  components: {
    "Panel-CRUD": () => import("cms_infrastructure_web/src/sdk/Admin/CRUD/CRUD-List.vue"),
  },
  methods: {
    create: function () {
      this.toPage("components/User/Portal-User-CreateUpdate.vue");
    },
    edit: function (item) {
      this.toPage("components/User/Portal-User-CreateUpdate.vue", {
        id: item.id,
      });
    },
    role: function (item) {
      this.toPage("components/UserRole/Portal-UserRole.vue", {
        filter: [
          {
            id:"UserId",
            name: "UserId",
            value: item.id,
            friendlyValue: item.name,
          },
        ],
      });
    },
  },
};
</script>